import './home.css';
import React from 'react'
import Navbar from "../../components/Header/Navbar/Navbar.js"
import Topnavbar from "../../components/Header/Topnavbar/Topnavbar.js"
import Carousel from "../../components/Carousel/Carousel.js"
import Footer from "../../components/Footer/Footer.js"
import Video from "../../assets/video/introwix.mp4"
import Articles from "../../database/articles.js"

function Home() {
  return (
    <>
      <Topnavbar/>
      <Navbar/>
      <div className='container-main-article1'>
        <div className='container-main-video'>
          <video src={Video} autoPlay loop/>
        </div>
        <div className='container-main-article1-desc'>
          <p>Cycling is a fantastic way of staying fit and discovering new areas. For many people,
          the high cost of brand-new bicycle is a discouragement, so  take an old second-hand bicycle, and clean it , repair it, and maybe even improve it. 
          By restoring an old bicycle you can save money, as well as gaining a really good understanding of how your bike works, and also have the chance to personalise it.
          </p>
        </div>
      </div>
      <div className='container-info'>
      <p className='info-p'>Development of this website is recorded. Click <a href="https://youtu.be/7bS74Hd1hrI?si=nENrZSNbFeUl_3zL" target="_blank" >HERE</a> if you are interested to see more.</p>
      </div>
      <Carousel/>
      <div className='container-main-article2'>
        <h2>6 Iconic Bicycles from the Eastern Europe</h2>
        <p>During the Soviet era, foreign-made bicycles were a rarity, making locally produced models highly coveted. Here are six of the most remarkable bicycles from that era:</p>
      </div>
      <div className='container-article2-cards'>
        {Articles.map((value,index) => (
          <div className='card' >
              <div>
                  <img className='image' src={value.img} alt="" />
              </div>
              <div className='desc'>
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
              </div>
          </div>
        ))}
      </div>

      <Footer/>
    </>
  )
}

export default Home