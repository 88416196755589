import React from 'react';
import './navbar.css';
import styled from 'styled-components';
import LOGO from "../../../assets/LOGO.png"
import { Outlet, Link } from "react-router-dom";
import Burger from './Burger';

const Nav = styled.nav`
  width: 100%;
  height: 105px;
  border-bottom: 2px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border-bottom: 2px solid #ff0000;
  position: sticky;
  top: 0;
  z-index: 9;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;

  .logo {
    width: 80px;
    height: 80px;
    padding: 15px 15px;
  }
  a {
    font-family: 'Jockey One', sans-serif;
  }
`

const Navbar = () => {
  return (
    <Nav>
      <div >
      <Link to="/"><img className="logo" src={LOGO} alt="LOGO" /></Link>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar

