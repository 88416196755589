import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './pages/About/About.js';
import Bicycle from './pages/Bicycles/Bicycles.js';
import Events from './pages/Events/Events.js';
import Home from './pages/Home/Home.js';

import Signin from "./pages/Signin/Signin.js"
import Register from "./pages/Register/Register.js"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="bicycles" element={<Bicycle />} />
            <Route path="about" element={<About />} />
            <Route path="events" element={<Events />} />
            <Route path="signin" element={<Signin />} />
            <Route path="register" element={<Register />} /> 

            
           {/*  <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
