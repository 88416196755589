import React, { useState } from 'react';
import RightNav from './Rightnav';

const Burger = () => {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <div className={`burger ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </div>
      <RightNav open={open} />
    </>
  )
}

export default Burger