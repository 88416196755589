import Register from "../../../pages/Register/Register.js"
import Signin from "../../../pages/Signin/Signin.js"
import Button from '@mui/material/Button';
import { Outlet, Link } from "react-router-dom";

import './topnavbar.css';
import React from 'react'

function Topnavbar() {
  return (
    <div className="container-topnavbar-grid">
      <small>Version 2.0.1</small>
      <div className="right"><Link to="/register"><Button size="small">Register</Button></Link></div>
      <div className="right"><Link to="/signin"><Button size="small">Signin</Button></Link></div>
      <Outlet />
    </div>
  )
}

export default Topnavbar