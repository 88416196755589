import './about.css';
import React from 'react'
import Navbar from "../../components/Header/Navbar/Navbar.js"
import Topnavbar from "../../components/Header/Topnavbar/Topnavbar.js"
import Footer from "../../components/Footer/Footer.js"

const About = () => {
  return (
    <>
    <Topnavbar/>
    <Navbar/>
    <div className='container-main-about'>
      <h1 className='Jockey'>More about project Cranky Bicycle</h1>
      <p>This project is seperated in two parts <b>Cranky bicycle</b> restoring old bicycles while having fun and <b>Programing</b> also while having fun.</p>
      <div className='container-about-both'>
        <div className='container-about-crankybicycle'>

          <h2>Cranky bicycle</h2>
          <div >
            <p>This is me</p>
            <img className="container-about-crankybicycle-image" src="https://crankybicycle.netlify.app/media/me.png" alt="Portfolio picture" />
            <p className='container-about-p' >A Guy who restores old bicycles.
              My name is Verners and i bought my first old bicycle in 2020 (55€). I bought some spare parts and i restored it. It gave me a pleasant feeling to bring back in life something old and forgotten. So i kept going.
              I can’t say im expert in this, but it's not rocket science. So i made crankybicycle to share my experience, to help you with tips and tricks, to motivate you.
              If you have an interesting restoration story, please feel free to share it with me.
            </p>
          </div>
          <div>
            <p className='container-about-p'>And this was my old bicycle which i bought in 2020 - <b>The Tourist</b></p>
            <img className="container-about-crankybicycle-bike" src="https://crankybicycle.netlify.app/media/bike_tourist.jpg" alt="The Tourist" />
            <p className='container-about-p'>This was one of the most elitist models in the whole of the Soviet Union. Its owners were fast and envied. The Tourist was designed for long cross-country rides. It had bent steering handles: although they resembled the horns of a ram, it placed the rider in a position that was best suited for fast and long rides, in terms of aerodynamics. One of the most notable features of this model was its revolutionary gear system that allowed for selecting between one of the four gears depending on the rider’s speed. Plus, the bicycle came with a stylish leather bag for keeping tools underneath the seat.</p>
          </div>

        </div>

        <div className='container-about-programing'>
          <h2>
          Programing
          </h2>
          <p className='container-about-p'>You know the saying: Teaching someone else is the best way to learn?
            <br/><br/>
            So i decided to create videos about how I created this website from scratch,
            and for sure there will be other interesting things about this project in to the future.
            <br/><br/>
            <iframe  src="https://www.youtube.com/embed/itbNDFwjJVs?si=hh9hLMgzUDo10eC_" title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
            </iframe>
            <br/>
            <br/>
            With these videos I'm trying to show how websites are made, trying to explain everything in a simple way. 
            And I'm not gonna lie this actually is more like a rocket science, but at some point it starts to make more sense. 
            So if you are a someone who wants to understand web development, or someone who is trying to change your career, 
            I hope this will help , because it was difficult to find information about how other developers are structuring 
            there projects, and tools they use to make things happen. So enjoy!
            <br/>
            And by the way , if you are wondering about my background, I have a personal website: 
            <br/>
            <a href="https://vernersgrzibovskis.com/" target="_blank">vernersgrzibovskis.com</a>
          </p>
        </div>
      </div>
    </div>
    <Footer/>
  </>
  )
}

export default About