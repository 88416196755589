
import React from 'react';
import styled from 'styled-components';
import { Outlet, Link } from "react-router-dom";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 10px 10px;
    margin-right: 3em;
    text-align: center;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.1px);
    position: fixed;
    display: ${({ open }) => open ? 'flex' : 'none'};
    top: 0;
    right: 0;
    height: 250px;
    width: 110px;
    padding-top: 8.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      background: #f5b41c;
      margin: 0.15em;
      width: 60px;
      box-shadow: 0px 1px 3px #143d59;
    }

    a {
      color: #143d59;
      font-family: 'Jockey One', sans-serif;
    }
    
    a:hover {
      color: #ff0000 ;
      transition: all .35s ease;
  }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/bicycles">BICYCLE</Link></li>
        <li><Link to="/about">ABOUT</Link></li>
        <li><Link to="/events">EVENTS</Link></li>
    </Ul>
  )
}

export default RightNav