import './carousel.css';
import React from 'react'
//import database from "../../database.json"

import Img1 from "../../assets/swiper1.JPG"
import Img2 from "../../assets/swiper2.JPG"
import Img3 from "../../assets/swiper3.JPG"

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/autoplay";

function Carousel() {
  return (
    <div className='container-carousel'>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        autoplay={{delay: 4000}}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        >
        <SwiperSlide><img className='carousel-image' src={Img1} alt="Bike" /></SwiperSlide>
        <SwiperSlide><img className='carousel-image' src={Img2} alt="Bike" /></SwiperSlide>
        <SwiperSlide><img className='carousel-image' src={Img3} alt="Bike" /></SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Carousel