import './footer.css';
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  return (
  <>
    <div className='footer-menu'>
      <div>
        <ul className='footer-contacts'>
          <li>Riga, Latvia.</li>
          <li>Street name</li>
          <li>+371 0000 00</li>
        </ul>
      </div>
      <div>
        <ul className='footer-soc'>
          <li><a href="https://www.instagram.com/crankybicycle/" target="_blank"><InstagramIcon sx={{ fontSize: 35 }}/></a></li>
          <li><a href="https://www.facebook.com/profile.php?id=61556912855534" target="_blank"><FacebookIcon sx={{ fontSize: 35 }}/></a></li>
          <li><a href="https://youtu.be/7bS74Hd1hrI?si=IeTwiVC9UPhhc_EL" target="_blank"><YouTubeIcon sx={{ fontSize: 35 }}/></a></li>
        </ul>
      </div>
    </div>
    <div className='footer-c'>
      <small>© all rights reserved</small>
    </div>
  </>
  )
}

export default Footer