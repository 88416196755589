import './register.css';
import React from 'react'
import Navbar from "../../components/Header/Navbar/Navbar.js"
import Topnavbar from "../../components/Header/Topnavbar/Topnavbar.js"
import Footer from "../../components/Footer/Footer.js"

function Register() {
  return (
    <>
      <Topnavbar/>
      <Navbar/>
      <div className='container-main-register'>
        <div className='container-main-circle'>
          <p><b>Register page</b>: Under construction</p>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Register