import './bicycle.css';
import React from 'react'
import Navbar from "../../components/Header/Navbar/Navbar.js"
import Topnavbar from "../../components/Header/Topnavbar/Topnavbar.js"
import Footer from "../../components/Footer/Footer.js"
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import YouTubeIcon from '@mui/icons-material/YouTube';

import {SteelBike, Gazelle, Guci, Ural, Zil} from '../../database/images.js';
import ImageGallery from "react-image-gallery";





function Bicycle() {

  return (
    <>
      <Topnavbar/>
      <Navbar/>
      <div className='container-main-bicycles'>
      <h1 className='Jockey'>RESTORED BICYCLES</h1>
        <div className='container-main-bicycles-desc'>
          <h2>STEEL BIKE</h2>
          <p>Bike is made of several steel parts to be light and trustworthy. Steel frame and fork. Old shimano pedals , rims and spokes are from steel. Combine with design of mine. VERSAČE</p>
          <a href="https://youtu.be/D2FCoOj5HXI" target="_blank"><YouTubeIcon sx={{ fontSize: 45 }}/></a>
        </div>
      <ImageGallery items={SteelBike} showPlayButton={false} showFullscreenButton={false}/>
        <div className='container-main-bicycles-desc'>
          <h2>GAZELLE</h2>
          <p>Royal Dutch Gazelle is the largest and most famous bicycle manufacturer in the Netherlands. Equipped with old drum brakes which are no longer common and cool headlight.</p>
        </div>
      <ImageGallery items={Gazelle} showPlayButton={false} showFullscreenButton={false}/>
        <div className='container-main-bicycles-desc'>
          <h2>Guci</h2>
          <p>This bike was made by mixing two old bikes and some spare parts i had. Originally bike with the female frame was damaged, and i had a spare frame which was in good condition. Bike itself is very light, if you change the rear wheel.</p>
        </div>
      <ImageGallery items={Guci} showPlayButton={false} showFullscreenButton={false}/>
        <div className='container-main-bicycles-desc'>
          <h2>URAL</h2>
          <p>The Ural went down in history as a highly-durable cross-country bike, resistant to deterioration and suitable for brutal workloads. Indeed, Soviet people tested this model for years by using it to transport everything from canisters to wood to pipes to fishing rods — it withstood all pressure with ease.</p>
        </div>
      <ImageGallery items={Ural} showPlayButton={false} showFullscreenButton={false}/>
        <div className='container-main-bicycles-desc'>
          <h2>OJSC AMO ZiL</h2>
          <p>Known fully as the Public Joint-Stock Company – Likhachov Plant. Was a major Russian automobile, truck, military vehicle, and heavy equipment manufacturer that was based in Moscow, Russia.</p>
        </div>
      <ImageGallery items={Zil} showPlayButton={false} showFullscreenButton={false}/>
      </div>
      <Footer/>
    </>
  )
}

export default Bicycle