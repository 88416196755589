import './signin.css';
import React from 'react'
import Navbar from "../../components/Header/Navbar/Navbar.js"
import Topnavbar from "../../components/Header/Topnavbar/Topnavbar.js"
import Footer from "../../components/Footer/Footer.js"

function Signin() {
  return (
    <>
      <Topnavbar/>
      <Navbar/>
      <div className='container-main-signin'>
        <div className='container-main-circle'>
          <p><b>Signin page</b>: Under construction</p>
        </div>
      </div>
     <Footer/>
    </>
  )
}

export default Signin